.app__body {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    

    @media screen and (max-width:800px){
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        // margin: auto;
        width: 100%;
        
    }

    .body-left {
        border-radius: 0.5rem;
        width: 100%;
        height: 50vh;
        margin: 5px;
        padding: 5px;
        // border: 2px solid red;
        background-color: rgb(105, 4, 4);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: var(--white-color);
        font-size: medium;
        text-shadow: 1px 1px 1px var(--crimson-color),
               2px 2px 1px var(--crimson-color);

               a{
                color: var(--primary-color);
                text-decoration: none;
                flex-direction: column;
                text-transform: uppercase;
                font-weight: 500;
                transition: all 0.3s ease-in-out;

                &:hover{
                    color:var(--secondary-color);
                }
            }

      }
      
      .body-right {
        width: 100%;
        height: auto;
        margin-left: 10rem;
        margin-right: auto;
        margin-bottom: 0.5rem;



    @media screen and (max-width:800px){
        margin-left: auto;
        
        
    }
        
        // border: 2px solid green;
        
      }
  }
  
 

.app__dex{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; 
    width: 310px;
    flex-direction: column;
    // margin:5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgb(105, 4, 4);
    color:#000;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
        box-shadow: 0 0 25px rgba(0,0,0,0.5);
    }
    @media screen and(min-width:2000px) {
        width: 470px;
        padding: 1.25 rem;
        border-radius: 0.75rem;
    }
    @media screen and(max-width:300px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 800px){
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        
        
    }
    @media screen and (max-width: 600px){
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        
        
    }

    p{
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: var(--white-color);
        font-size: xx-large;
        text-shadow: 1px 1px 1px var(--crimson-color),
               2px 2px 1px var(--crimson-color);

        margin: 1rem;
    }

    button{
        
        margin: 0.5rem;
        padding: 0.5rem 0.5rem;
        border-radius: 10px;
        border: none;
        background-color: var(--gray-color);
        font-weight: 300;
        color: var(--white-color);
        outline: none;
        // margin: 2rem 0 0;
        font-family: var(--font-base);
        cursor: pointer;
    }
    button:hover{
        color:#000;
        background-color: #fff;
    }


    select {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #af0513;
        color: white;
        padding: 12px;
        width: 100%;
        border: none;
        font-size: 20px;
        border-radius: 0.5rem;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
        -webkit-appearance: button;
        outline: none;
      }

     


    .app__dex_Image{
        width: 200px;
        height: 100%;
        position: relative;
        margin: 1rem 1rem;
        img {
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
            object-fit: cover;
        }
        @media screen and(min-width:2000px) {
            height: 350px;
        }
    
      
    
        }


    .app__dex_desc{
        p{
            font-size: medium;
        }
    }

       

 }


 
