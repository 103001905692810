.app__navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    background: rgba(255,255,255, 0.5);
    -webkit-backdrop-filter: blur(4px);   
    border: 1px solid rgba(255,255,255, 0.1);
    position: fixed; 
    top: 0px;
    z-index: 2;

 }

 .app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img{
        width: 90px;
        height: 35px;
        @media screen and (min-width:2000 px) {
            width: 180px;
            height: 40px;
        }
    }
 }

 .app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;

        li{
            margin: 0 1rem;
            cursor: pointer;
            flex-direction: column;

            div{
                width: 5px;
                height: 5px;
                background: transparent;
                border-radius: 50%;
                margin-bottom: 5px;
            }
            a{
                color: var(--primary-color);
                text-decoration: none;
                flex-direction: column;
                text-transform: uppercase;
                font-weight: 500;
                transition: all 0.3s ease-in-out;

                &:hover{
                    color:var(--secondary-color);
                }
            }
        &:hover{
            div{
                background: var(--secondary-color);
            }
        }  
    }

    // @media screen and (max-width: 900px){
    //     display: none;
        
    // }
   
}

