.app__footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    background: rgba(255,255,255, 0.5);
    -webkit-backdrop-filter: blur(4px);   
    border: 1px solid rgba(255,255,255, 0.1);
    position: bottom; 
    top: 0px;
    z-index: 2;

 }