body{
    background-color: #1A1A1D;
}

.copyright {
    width: 100%;
    // padding: 2rem 0 0;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  
    p {
      text-transform: uppercase;
      color: var(--black-color);
    }
    @media screen and (max-width: 500px) {
      align-content: center;
    }
  }